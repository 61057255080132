import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import { auth, gAuthProvider } from 'services/Firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut as firebaseSignOut, signInWithPopup, sendEmailVerification } from "firebase/auth";
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';

function useAuth() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

	const query = useQuery();

    const { token, signedIn } = useSelector((state) => state.auth.session);

    const signIn = async (values) => {
        try {
			const { password, email } = values;
			const resp = await signInWithEmailAndPassword(auth, email, password);
			// const resp = await apiSignIn(values);
			if (resp.user) {
				dispatch(onSignInSuccess(resp.user));
				dispatch(setUser({
					id: resp.user.uid,
					avatar: '/img/avatars/thumb-1.jpg', 
					userName: resp.user.email, 
					authority: ['admin', 'user'],
					email: resp.user.email
				}));
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.code || errors.toString(),
            }
		}
    }

	const signInWithGooglePopup = async () => {
		try {
			const resp = await signInWithPopup(auth, gAuthProvider);
			// This gives you a Google Access Token. You can use it to access the Google API.
			// const credential = GoogleAuthProvider.credentialFromResult(resp);
			// const token = credential.accessToken;
			// The signed-in user info.
			// const user = resp.user;
			// IdP data available using getAdditionalUserInfo(result)
			if (resp.user) {
				dispatch(onSignInSuccess(resp.user));
				dispatch(setUser({
					id: resp.user.uid,
					avatar: '/img/avatars/thumb-1.jpg', 
					userName: resp.user.email, 
					authority: ['admin', 'user'],
					email: resp.user.email
				}));
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                }
			}
		} catch (errors) {
			// const errorCode = errors.code;
			// const errorMessage = errors.message;
			// // The email of the user's account used.
			// const email = errors.customData.email;
			// // The AuthCredential type that was used.
			// const credential = GoogleAuthProvider.credentialFromError(errors);
			return {
                status: 'failed',
                message: errors?.code || errors.toString(),
            }
		}
	}

	const signUp = async (values) => {
        try {
			const { password, email } = values;
			const resp = await createUserWithEmailAndPassword(auth, email, password);
			if (resp.user) {
				dispatch(onSignInSuccess(resp.user));
				dispatch(setUser({
					id: resp.user.uid,
					avatar: '/img/avatars/thumb-1.jpg', 
					userName: resp.user.email, 
					authority: ['admin', 'user'],
					email: resp.user.email
				}));
				try {
					sendEmailVerification(resp.user);
				} catch (sendEmailVerificationError) {
					console.log(sendEmailVerificationError);
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.code || errors.toString(),
            }
		}
    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess());
		dispatch(setUser(initialState));
		navigate(appConfig.unAuthenticatedEntryPath);
	}

    const signOut = async () => {
		await firebaseSignOut(auth);
		handleSignOut();
	}
    
    return {
        authenticated: signedIn && token,
        signIn,
		signInWithGooglePopup,
		signUp,
        signOut,
    }
}

export default useAuth;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// --dev --local 
// const firebaseConfig = {
//   apiKey: "AIzaSyDFWbkd7vZ45eaRCJ6iuFASVYVwAdalLHc",
//   authDomain: "organized-pixel-code-v0.firebaseapp.com",
//   projectId: "organized-pixel-code-v0",
//   storageBucket: "organized-pixel-code-v0.appspot.com",
//   messagingSenderId: "247801892752",
//   appId: "1:247801892752:web:5acf4e8c9cd9b379ac2d15",
//   measurementId: "G-YC8JQ2VPBQ"
// };

// --prod 
const firebaseConfig = {
  apiKey: "AIzaSyCaVJELtJbBw5iX91f3b1vURcADzikj8Gk",
  authDomain: "keepmycodes.firebaseapp.com",
  projectId: "keepmycodes",
  storageBucket: "keepmycodes.appspot.com",
  messagingSenderId: "991421062478",
  appId: "1:991421062478:web:420a31e9cbad149b24921f",
  measurementId: "G-WQY0KNN30C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const gAuthProvider = new GoogleAuthProvider();
// const analytics = getAnalytics(app);
export default function codeFakeApi (server, apiPrefix) {

    server.post(`${apiPrefix}/code/list`, (schema, {requestBody}) => {
        const { sort, search } = JSON.parse(requestBody)
        let data = schema.db.codeList
        if(sort === 'asc') {
            data = data.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }
        if(sort === 'desc') {
            data = data.sort((a, b) => (a.name > b.name) ? -1 : 1)
        }
        // if (search) {
        //     data = wildCardSearch(data, search)
        // }
        return data
    })

    server.get(`${apiPrefix}/code/by-id`, (schema, {queryParams}) => {
        const id = queryParams.id
        const code = schema.db.codeList.find(id)
        return code
    })

    server.post(`${apiPrefix}/code/create`, (schema, {requestBody}) => {
        const data = JSON.parse(requestBody)
        schema.db.codeList.insert(data)
        return true
    })

    server.put(`${apiPrefix}/code/update`, (schema, {requestBody}) => {
        const data = JSON.parse(requestBody)
        const { id } = data
        schema.db.codeList.update({id}, data)
        return true
    })

    server.del(`${apiPrefix}/code/delete`, (schema, {requestBody}) => {
        const { id } = JSON.parse(requestBody)
        schema.db.codeList.remove({id})
        return true
    })

}
import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
	name: 'auth/session',
	initialState: {
        token: '',
        fbuser: null,
        signedIn: false,
    },
	reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true;
            state.fbuser = action.payload;
            // this is kept to keep state check on a string value instead of entire 'fbuser' object
            state.token = action.payload.accessToken ? action.payload.accessToken : action.payload.idToken;
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false;
            state.fbuser = null;
            state.token = '';
        },
        setToken: (state, action) =>  {
            state.token = action.payload;
        }
	},
});

export const { onSignInSuccess, onSignOutSuccess, setToken } = sessionSlice.actions;

export default sessionSlice.reducer;
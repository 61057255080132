export const codeList = [
	{
		id: 'b8579d77-860b-4120-94f8-913623df9831',
		name: 'QR Coode 1',
		desc: 'Most of you are familiar with the virtues of a programmer',
        dynamic: true,
        url: 'http://localhost:3000/code/list',
        redirectUrl: 'http://localhost:3003/scan/register',
        active: true,
        createdDt: new Date().toISOString(),
        updatedDt: new Date().toISOString(),
		projects: [
            {
                id: 'a8579d77-860b-4120-94f8-913623df9831',
                name: 'Project 1'
            },
            {
                id: 'a8579d77-860b-4120-94f8-913623df9832',
                name: 'Project 2'
            }
        ]
	},
	{
		id: 'b8579d77-860b-4120-94f8-913623df9833',
		name: 'QR Coode 3',
		desc: 'Most of you are familiar with the virtues of a developer',
        dynamic: true,
        url: 'http://localhost:3000/code/list',
        redirectUrl: 'http://localhost:3003/scan/register',
        active: false,
        createdDt: new Date().toISOString(),
        updatedDt: new Date().toISOString(),
		projects: [
            {
                id: 'a8579d77-860b-4120-94f8-913623df9831',
                name: 'Project 1'
            },
            {
                id: 'a8579d77-860b-4120-94f8-913623df9833',
                name: 'Project 3'
            }
        ]
	},
    {
		id: 'b8579d77-860b-4120-94f8-913623df9834',
		name: 'QR Coode 4',
		desc: 'Most of you are familiar with the virtues of a program manager',
        dynamic: false,
        url: 'http://localhost:3000/code/list',
        redirectUrl: null,
        active: true,
        createdDt: new Date().toISOString(),
        updatedDt: new Date().toISOString(),
		projects: [
            {
                id: 'a8579d77-860b-4120-94f8-913623df9831',
                name: 'Project 1'
            }
        ]
	},
    {
		id: 'b8579d77-860b-4120-94f8-913623df9835',
		name: 'QR Coode 5 for tracking trackable data that is for tracking sake',
		desc: 'Most of you are familiar with the virtues of a dev manager',
        dynamic: true,
        url: 'http://localhost:3000/code/list',
        redirectUrl: 'http://localhost:3003/scan/register',
        active: true,
        createdDt: new Date().toISOString(),
        updatedDt: new Date().toISOString(),
		projects: [
            {
                id: 'a8579d77-860b-4120-94f8-913623df9831',
                name: 'Project 1'
            },
            {
                id: 'a8579d77-860b-4120-94f8-913623df9832',
                name: 'Project 2'
            }
        ]
	},
    {
		id: 'b8579d77-860b-4120-94f8-913623df9836',
		name: 'QR Coode 6',
		desc: 'Most of you are familiar with the virtues of a product manager',
        dynamic: false,
        url: 'http://localhost:3000/code/list',
        redirectUrl: null,
        active: true,
        createdDt: new Date().toISOString(),
        updatedDt: new Date().toISOString(),
		projects: null
	},
    {
		id: 'b8579d77-860b-4120-94f8-913623df9837',
		name: 'QR Coode 7',
		desc: null,
        dynamic: true,
        url: 'http://localhost:3000/code/list',
        redirectUrl: 'http://localhost:3003/scan/register',
        active: false,
        createdDt: new Date().toISOString(),
        updatedDt: new Date().toISOString(),
		projects: [
            {
                id: 'a8579d77-860b-4120-94f8-913623df9831',
                name: 'Project 1'
            },
            {
                id: 'a8579d77-860b-4120-94f8-913623df9832',
                name: 'Project 2'
            },
            {
                id: 'a8579d77-860b-4120-94f8-913623df9892',
                name: 'Free crypto puppies'
            },
            {
                id: 'a8579d77-860b-4120-94f8-913623df9893',
                name: 'Even more awesome project with very long name'
            }
        ]
	},
    {
		id: 'b8579d77-860b-4120-94f8-913623df9838',
		name: 'QR Coode 8',
		desc: 'Most of you are familiar with the virtues of a human resource',
        dynamic: true,
        url: 'http://localhost:3000/code/list',
        redirectUrl: 'http://localhost:3003/scan/register',
        active: true,
        createdDt: new Date().toISOString(),
        updatedDt: new Date().toISOString(),
		projects: null
	},
    {
		id: 'b8579d77-860b-4120-94f8-913623df9839',
		name: 'QR Coode 9',
		dynamic: false,
        url: 'http://localhost:3000/code/list',
        redirectUrl: null,
        active: true,
        createdDt: new Date().toISOString(),
		projects: null
	},
    {
		id: 'b8579d77-860b-4120-94f8-913623df983a',
		name: 'QR Coode 10',
        dynamic: true,
        url: 'http://localhost:3000/code/list',
        redirectUrl: 'http://localhost:3003/scan/register',
        active: false,
        createdDt: new Date().toISOString(),
        updatedDt: new Date().toISOString(),
	},
    {
		id: 'b8579d77-860b-4120-94f8-913623df983b',
		name: 'QR Coode 11',
		desc: 'Most of you are familiar with the virtues of a support',
        dynamic: false,
        url: 'http://localhost:3000/code/list',
        redirectUrl: null,
        active: true,
        createdDt: new Date().toISOString(),
        updatedDt: new Date().toISOString(),
		projects: null
	}
]

import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function useUuidV4() {
    const idRef = useRef();

    if (!idRef.current) {
        idRef.current = uuidv4();
    }

    return idRef.current;
}